// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: '0.0.0-alpha',
  appTitle: 'SWIFT Global',
  nonProdName: 'LOCAL',
  webApiBaseUrl: 'https://localhost:44365/api/',
  //webApiBaseUrl: 'https://swiftglobaldev.beav.com/webapi/api/',

  reportServerEmbedUrlBase: 'https://swiftglobalreportsdev.beav.com/Reports/report/SwiftGlobal',
  dateFormat: 'DD MMM YYYY',
  timeFormat: 'hh:mm A zz',
  idpConfig: {
    issuer: 'https://logindev.rockwellcollins.com:443',
    clientId: 'IS_Quote_dev'
  },
  devSettings: { // use a dictionary approach so this can be an empty object in prod build
    "useMockDataApi": true,
    "devModeSecret" : "XXX_PW_HERE_XXX",
    "devModeUserId" : ""
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
