import {AfterViewInit, Component, ViewChild, ViewContainerRef} from "@angular/core";

import {ICellEditorAngularComp} from "ag-grid-angular";

@Component({
    selector: 'code-cell',
    template: `<input #input type="checkbox" [(ngModel)]="value" >`
})
export class IsActiveEditor implements ICellEditorAngularComp, AfterViewInit {
    ngAfterViewInit(): void {
        
    }
    private params: any;
    public value: boolean;
    private cancelBeforeStart: boolean = false;

    @ViewChild('input', {read: ViewContainerRef,static:false}) public input;


    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
    }

    getValue(): any {
        return this.value;
    }

    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd(): boolean {
        return false;
    };
    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    // ngAfterViewInit() {
    //     window.setTimeout(() => {
    //         this.input.element.nativeElement.focus();
    //     })
    // }
    focusIn(){
        window.setTimeout(() => {
            this.input.element.nativeElement.focus();
        })
    }
}