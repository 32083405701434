import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showSpinner : boolean = true;
  messages: string[] = [];

  constructor(public authService: AuthenticationService, public router: Router) { }

  ngOnInit() {
    console.debug(this.authService);

    this.authService.isAuthenticated$.subscribe(auth => {
      this.messages.push(auth ? 'Authentication complete' : 'Authenticating...');
    })

    this.authService.isLoginResolved$.subscribe(resolved => {
      this.messages.push(resolved ? 'Authorization complete' : 'Authorizing...');
    });

    this.authService.isLoggedIn$.subscribe(loggedIn => {

      if (!this.authService.isLoggedinResolved()) {
        return;
      }

      console.debug(`login#loggedin status ${loggedIn}`);

      if (!loggedIn) {
        this.messages.push('Authorization failed');
        this.router.navigateByUrl('/public/forbidden');
        return;
      }


      this.messages.push('Authorization successful');
      
      if (this.authService.redirectUrl && this.authService.redirectUrl != '/') {
        this.messages.push(`Redirecting to ${this.authService.redirectUrl} ...`);
        this.router.navigateByUrl(this.authService.redirectUrl);
        return;
      }

      this.messages.push('Use the navigation above');
      this.showSpinner = false;

    })
  }

}
