import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


// Entire library of Angular Material components
import { MaterialComponentsModule } from '../material-modules/material-components.module';



import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


// Component examples
import { AutocompleteSimpleExample } from './autocomplete-simple-example/autocomplete-simple-example';
import { BottomSheetOverviewExample, BottomSheetOverviewExampleSheet } from './bottom-sheet-overview-example/bottom-sheet-overview-example';
import { CardFancyExample } from './card-fancy-example/card-fancy-example';
import { DialogOverviewExample, DialogOverviewExampleDialog } from './dialog-overview-example/dialog-overview-example';
import { DividerOverviewExample } from './divider-overview-example/divider-overview-example';
import { ExpansionOverviewExample } from './expansion-overview-example/expansion-overview-example';
import { ListSelectionExample } from './list-selection-example/list-selection-example';
import { MenuOverviewExample } from './menu-overview-example/menu-overview-example';
import { NestedMenuExample } from './nested-menu-example/nested-menu-example';
import { SelectOverviewExample } from './select-overview-example/select-overview-example';
import { SlideToggleOverviewExample } from './slide-toggle-overview-example/slide-toggle-overview-example';
import { SliderOverviewExample } from './slider-overview-example/slider-overview-example';
import { SortOverviewExample } from './sort-overview-example/sort-overview-example';
import { StepperOverviewExample } from './stepper-overview-example/stepper-overview-example';
import { TabGroupBasicExample } from './tab-group-basic-example/tab-group-basic-example';
import { ToolbarMultirowExample } from './toolbar-multirow-example/toolbar-multirow-example';
import { TreeNestedOverviewExample } from './tree-nested-overview-example/tree-nested-overview-example';


import { TableBasicExample } from './table-basic-example/table-basic-example';
import { ButtonToggleOverviewExample } from './button-toggle-overview-example/button-toggle-overview-example';


@NgModule({
	declarations: [
		AutocompleteSimpleExample,
		BottomSheetOverviewExample,
		CardFancyExample,
		DialogOverviewExample,
		DividerOverviewExample,
		ExpansionOverviewExample,
		ListSelectionExample,
		MenuOverviewExample,
		NestedMenuExample,
		SelectOverviewExample,
		SlideToggleOverviewExample,
		SliderOverviewExample,
		SortOverviewExample,
		StepperOverviewExample,
		TabGroupBasicExample,
		ToolbarMultirowExample,
		TreeNestedOverviewExample,
		BottomSheetOverviewExampleSheet,
		DialogOverviewExampleDialog,
		TableBasicExample,
		ButtonToggleOverviewExample
	],
	imports: [
		CommonModule,
		MaterialComponentsModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule
	],
	exports: [
		CommonModule,
		AutocompleteSimpleExample,
		BottomSheetOverviewExample,
		CardFancyExample,
		DialogOverviewExample,
		DividerOverviewExample,
		ExpansionOverviewExample,
		ListSelectionExample,
		MenuOverviewExample,
		NestedMenuExample,
		SelectOverviewExample,
		SlideToggleOverviewExample,
		SliderOverviewExample,
		SortOverviewExample,
		StepperOverviewExample,
		TabGroupBasicExample,
		ToolbarMultirowExample,
		TreeNestedOverviewExample,
		TableBasicExample,
		ButtonToggleOverviewExample,
	],
	entryComponents: [
		BottomSheetOverviewExampleSheet,
		DialogOverviewExampleDialog
	],
})



export class ComponentExamplesModule { }
