import { IdNameInterface, AssemblyCostToPriceInterface, AssemblyCostInterface } from './assembly-cost.interface'; /*, SeatTypeInterface*/
import { AssemblyPriceInterface } from './assembly-price.interface';

export const ASSEMBLY_COST: AssemblyCostInterface[] = [
{
		id:101,
		partNumber: 'CT-101',
		description: 'Cost Part Number A',
		paxPerAssembly: '1',
		quantityOfAssembly: '110',
		costPerAssembly: '80',
		adHocPercent: 15,
		additionalCost: 0,
		costOverride: '',
		total: 1200,
		laborPerPax: 100
}, {
		id: 102,
		partNumber: 'CT-102',
		description: 'Cost Part Number B',
		paxPerAssembly: '2',
		quantityOfAssembly: '160',
		costPerAssembly: '90',
		adHocPercent: 15,
		additionalCost: 0,
		costOverride: '',
		total: 1200,
		laborPerPax: 100
}, {
		id: 103,
		partNumber: 'CT-103',
		description: 'Cost Part Number C',
		paxPerAssembly: '3',
		quantityOfAssembly: '320',
		costPerAssembly: '100',
		adHocPercent: 15,
		additionalCost: 0,
		costOverride: '',
		total: 1200,
		laborPerPax: 100
}
];


export const ASSEMBLY_PRICE: AssemblyPriceInterface[] = [
	{
			id:11,
			partNumber: 'PT-11-A',
			description: 'Price Part Number A',
			paxPerAssembly: '1',
			quantityOfAssembly: '110',
			costPerAssembly: '80',
			adHocPercent: 15,
			additionalCost: 0,
			costOverride: '',
			total: 1200,
			laborPerPax: 100
	}, {
			id:12,
			partNumber: 'PT-12-B',
			description: 'Price Part Number B',
			paxPerAssembly: '2',
			quantityOfAssembly: '160',
			costPerAssembly: '90',
			adHocPercent: 15,
			additionalCost: 0,
			costOverride: '',
			total: 1200,
			laborPerPax: 100
	}, {
			id: 13,
			partNumber: 'PT-13-C',
			description: 'Price Part Number C',
			paxPerAssembly: '3',
			quantityOfAssembly: '320',
			costPerAssembly: '100',
			adHocPercent: 15,
			additionalCost: 0,
			costOverride: '',
			total: 1200,
			laborPerPax: 100
	}
];
// export const QUOTES_COST_TO_PRICE_DATA: QuoteCostToPriceDataInterface[] = [];
export const ASSEMBLY_COST_TO_PRICE: AssemblyCostToPriceInterface[] = [
		{ costId: 101, priceIds: [13], cost: null, price:[] },
		{ costId: 103, priceIds: [11, 12], cost: null, price: [] },
		{ costId: 102, priceIds: [12, 13], cost: null, price: [] }
];
//export const FEATURES_OF_SPECIFIC_SEAT_TYPE_LIST: SeatTypeInterface[] = [
//		{
//				name: 'airLounge',
//				list: [
//						{ id: 1, name: "BFE 3 point Shoulder Belt" },
//						{ id: 2, name: "BFE Airbag System" },
//						{ id: 3, name: "BFE Aramid Fabric" },
//						{ id: 4, name: "BFE Carpet" },
//						{ id: 5, name: "BFE E-leather dresscovers" },
//						{ id: 6, name: "BFE fabric (Complete)" },
//						{ id: 7, name: "BFE Fabric (cut & sew)" },
//						{ id: 8, name: "BFE laminated E-leather" },
//						{ id: 9, name: "BFE Laminated Fabric Dresscovers (Cut and Sew)" },
//						{ id: 10, name: "BFE Laminated Leather (complete)" },
//				]
//		}, {
//				name: 'airRest',
//				list: [
//						{ id: 1, name: "4 way headrest /pax" },
//						{ id: 2, name: "Audio IFE" },
//						{ id: 3, name: "BFE 3 point Shoulder Belt" },
//						{ id: 4, name: "BFE Air Belt" },
//						{ id: 5, name: "BFE Airbag System" },
//						{ id: 6, name: "BFE E-leather dresscovers" },
//						{ id: 7, name: "BFE Fabric (cut & sew)" },
//						{ id: 8, name: "BFE Laminated Fabric Dresscovers (Cut and Sew)" },
//						{ id: 9, name: "BFE Laminated Leather (complete)" },
//						{ id: 10, name: "BFE Laminated Leather (cut & sew)" }]

//		}, {
//				name: 'apexSeat',
//				list: [
//						{ id: 1, name: "4 way headrest /pax" },
//						{ id: 2, name: "BFE Aramid Fabric" },
//						{ id: 3, name: "BFE Carpet" },
//						{ id: 4, name: "BFE Fabric (cut & sew)" },
//						{ id: 5, name: "BFE Laminated Fabric Dresscovers (Cut and Sew)" },
//						{ id: 6, name: "BFE Laminated Leather (cut & sew)" },
//						{ id: 7, name: "BFE Laminates" },
//						{ id: 8, name: "BFE Lapbelts" },
//						{ id: 9, name: "Feature 1" },
//						{ id: 10, name: "Feature 2" }
//				]
//		}, {
//				name: 'aspire',
//				list: [
//						{ id: 1, name: "4 way headrest /pax" },
//						{ id: 2, name: "6 way headrest" },
//						{ id: 3, name: "AN64 (GR2)" },
//						{ id: 4, name: "Antimacassar Provision" },
//						{ id: 5, name: "Articulating Seat Pan" },
//						{ id: 6, name: "Audio IFE" },
//						{ id: 7, name: "BFE Air Belt" },
//						{ id: 8, name: "BFE E-leather dresscovers" },
//						{ id: 9, name: "BFE fabric (Complete)" },
//						{ id: 10, name: "BFE Fabric (cut & sew)" },
//				]
//		}];


