import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-non-responsive-squishy',
	templateUrl: './non-responsive-squishy.component.html',
	styleUrls: ['./non-responsive-squishy.component.scss']
})
export class NonResponsiveSquishyComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}


@Component({
        selector: 'app-non-responsive-squishy-route-wrapper',
        templateUrl: './non-responsive-squishy-route-wrapper.html'
})
export class NonResponsiveSquishyRouteWrapper implements OnInit {

	constructor() {
	}

        ngOnInit() {}
}

