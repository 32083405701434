import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthUser } from '../models/auth/auth-user.model';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService extends BaseApiService {

  private domainUrl = environment.webApiBaseUrl + 'auth';  // URL to web api

  constructor(protected http: HttpClient, protected snackBar: MatSnackBar) { //private messageService: MessageService
    super(http, snackBar);
  }

  login(accessToken: string, secret: string = ""): Observable<string> {
    const url = `${this.domainUrl}/login/accountlogin`;
    return this.getResponse(url, accessToken, secret);
  }

  impersonate(accessToken: string) : Observable<string> {
    const url = `${this.domainUrl}/login/impersonateaccount`;
    return this.getResponse(url, accessToken);
  }

  private getResponse(url: string, accessToken: string, secret: string = ""): Observable<string> {

    let httpHeaders = new HttpHeaders({"accessToken": accessToken, "Content-Type": "text"});
    if (secret !== "") {
      httpHeaders.append("devModeSecret", secret);
    }

    return this.http.post(url, null, {
      headers: httpHeaders,
      observe: "response",
      responseType: 'text'
    }).pipe(
      map(response => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //localStorage.setItem('currentUser', JSON.stringify(response));
        console.debug(`jwt received: ${response.body}`);
        return response.body;
      }),
      catchError(this.handleError<string>(`login error`))
    );
  }

  // return this._http.get('https://localhost:44365/api/auth/login/accountlogin',
  //     {
  //       headers: new HttpHeaders(
  //         {
  //           "accessToken": accessToken,
  //           "devModeSecret": devModeSecret,
  //           "Content-Type": "text"
  //         }),
  //       observe: 'response',
  //       responseType: 'text'
  //     }).subscribe(data => {
  //       console.log("JWT Response: " + data.body),
  //         localStorage.setItem("token", data.body),
  //         this.authenticatedUser = JSON.parse(window.atob(data.body.split('.')[1])),
  //         this.LogUserModel();
  //     });


}
