import { Component, OnInit } from '@angular/core';


//import { WindowRef } from '../../WindowRef';


import * as $ from 'jquery';

declare var window: any;

@Component({
	selector: 'app-live-style-guide',
	templateUrl: './live-style-guide.component.html',
	styleUrls: ['./live-style-guide.component.scss']
})



export class LiveStyleGuideComponent implements OnInit {

	//myWin: any;

	//public winRef: WindowRef;

	//winRef: WindowRef;

	//myAwesomeWindow = this.winRef;

	constructor() {


		var myWin = window;

		console.log(window);


		myWin.onload = function() {

			myWin.runMainHeaderAnimation = function() {

				var swiftGlobalStringOfScrambledMessages = ["SWIFT GLOBAL"];
				var liveStyleGuideStringOfScrambledMessages = ["LIVE STYLE GUIDE"];
				var versionOnePointZeroPointZeroStringOfScrambledMessages = ["VERSION 1.0.0"];

				var collinsAeroSpaceStringOfScrambledMessages = ["COLLINS AEROSPACE"];

				//

				var theScrambledMessage = new ScrambledMessage( $('#main-header-headline-id'), swiftGlobalStringOfScrambledMessages, 500 );
				var theScrambledMessage = new ScrambledMessage( $('#main-header-headline-part-2-id'), liveStyleGuideStringOfScrambledMessages, 600 );
				var theScrambledMessage = new ScrambledMessage( $('#main-header-style-guide-version-number-id'), versionOnePointZeroPointZeroStringOfScrambledMessages, 700 );

				var theScrambledMessage = new ScrambledMessage( $('#main-header-subheadline-id'), collinsAeroSpaceStringOfScrambledMessages, 1000 );

			}

			var ScrambledMessage = function( el, theMessage, startTimeScrambleDelay ) {

				//'use strict';
				var m = this;

				m.init = function() {

					m.codeletters = "&#*+%?£@§$Q0P1H7&@!U+!9Z@]?[6()&#*+%?£@§$Q0P1H7&@!U+!9Z@]?[6()&#*+%?£@§$Q0P1H7&@!U+!9Z@]?[6()&#*+%?£@§$Q0P1H7&@!U+!9Z@]?[6()";
					m.message = 0;
					m.current_length = 0;
					m.fadeBuffer = false;

					m.messages = theMessage;

					// Set's the start time
					setTimeout( m.animateIn, startTimeScrambleDelay );
				};

				m.generateRandomString = function(length) {
					var random_text = '';

					while(random_text.length < length) {
						random_text += m.codeletters.charAt(Math.floor(Math.random()*m.codeletters.length));
					}

					return random_text;
				};

				m.animateIn = function() {

					if(m.current_length < m.messages[m.message].length){

						// The last number in this line of code is what defines how many letters animate in at a time.
						// Meaning ... the smaller the number, the longer it takes for the entire message to animate in.
						// This could probably be written so that the rate of change is dynamic in that it has easing.
						//m.current_length = m.current_length + 1;

						m.current_length = ( m.current_length * 1.1 ) + 1;

						if(m.current_length > m.messages[m.message].length) {
							m.current_length = m.messages[m.message].length;
						}

						var message = m.generateRandomString(m.current_length);
						$(el).html(message);

						// This sets the time for "each" letter to "initially" show up on the screen.
						// A high number will make it so that there's a long pause between eash number that shows up.
						// Meaning, it'll make it talke a long time for the entire message to be written out.
						setTimeout(m.animateIn, 20);

					} else {
						setTimeout(m.animateFadeBuffer, 20);
					}
				};

				m.animateFadeBuffer = function() {

					if(m.fadeBuffer === false){
						m.fadeBuffer = [];

						for(var i = 0; i < m.messages[m.message].length; i++){
							m.fadeBuffer.push({c: (Math.floor(Math.random()*12))+1, l: m.messages[m.message].charAt(i)});
						}
					}

					var do_cycles = true;
					var message = '';

					for(var i = 0; i < m.fadeBuffer.length; i++){
						var fader = m.fadeBuffer[i];

						if (fader.c > 0) {
							do_cycles = true;
							fader.c--;
							message += m.codeletters.charAt(Math.floor(Math.random()*m.codeletters.length));

						} else {
							message += fader.l;
						}
					}

					$(el).html(message);

					if ( do_cycles === true ) {

						// Time it takes for all the letters to switch
						// Higher the number the more it looks like it's pausing
						setTimeout(m.animateFadeBuffer, 20);

					} else {

						setTimeout(m.cycleText, 2000);
					}
				};

				m.cycleText = function() {
					m.message = m.message + 1;

					if (m.message >= m.messages.length) {
						m.message = 0;
					}

					m.current_length = 0;
					m.fadeBuffer = false;

					$(el).html('');

					setTimeout(m.animateIn, 200);
				};

				m.init();

			}

			var swiftGlobalStringOfScrambledMessages = ["SWIFT GLOBAL"];
			var liveStyleGuideStringOfScrambledMessages = ["LIVE STYLE GUIDE"];
			var versionOnePointZeroPointZeroStringOfScrambledMessages = ["VERSION 1.0.0"];

			var collinsAeroSpaceStringOfScrambledMessages = ["COLLINS AEROSPACE"];

			//

			var theScrambledMessage = new ScrambledMessage( $('#main-header-headline-id'), swiftGlobalStringOfScrambledMessages, 500 );
			var theScrambledMessage = new ScrambledMessage( $('#main-header-headline-part-2-id'), liveStyleGuideStringOfScrambledMessages, 600 );
			var theScrambledMessage = new ScrambledMessage( $('#main-header-style-guide-version-number-id'), versionOnePointZeroPointZeroStringOfScrambledMessages, 700 );

			var theScrambledMessage = new ScrambledMessage( $('#main-header-subheadline-id'), collinsAeroSpaceStringOfScrambledMessages, 1000 );


		}

		//this.myWin = myWin;

		//smyWin.onload();

	}

	ngOnInit() {



		//this.myWin.runMainHeaderAnimation();
	}

	fiscalYearModel : any = "2019";
	fiscalYearModel_values: any = ["2019", "2018", "2017", "2016", "2015", "2014", "2013", "2012", "2011", "2010", "2009", "2008", "2007", "2006"];

	states = [
		{name: 'Arizona', abbrev: 'AZ'},
		{name: 'California', abbrev: 'CA'},
		{name: 'Colorado', abbrev: 'CO'},
		{name: 'New York', abbrev: 'NY'},
		{name: 'Pennsylvania', abbrev: 'PA'},
	];

	months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	];

	changemonths(event) {
		alert("Changed month from the Dropdown");
		console.log(event);
	}

}

@Component({
        selector: 'app-live-style-guide-route-wrapper',
        templateUrl: './live-style-guide-route-wrapper.html'
})

export class LiveStyleGuideRouteWrapper implements OnInit {

	constructor() {
	}

        ngOnInit() {}
}
