import { Injectable } from '@angular/core';
import { AccountPrefs } from '../models/lookup/user-prefs.model';

@Injectable({
  providedIn: 'root'
})
export class AccountPrefService {

  constructor() { }

  getAccountPrefs() : AccountPrefs {
    return JSON.parse(localStorage.getItem('prefs')) as AccountPrefs;
  }

  setAccountPrefs(prefs : AccountPrefs) {
    localStorage.setItem('prefs', JSON.stringify(prefs));
  }
}
