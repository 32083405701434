import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class MockDataInterceptor implements HttpInterceptor {

    constructor() {}
  
      intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // todo: conditionally inject this interceptor instead of doing logic here
        if (!environment.devSettings["useMockDataApi"] || false) {
            return next.handle(request);
        }

        let webApiBaseUrl = environment.webApiBaseUrl.toLowerCase();
        let origUrl = request.url.toLowerCase();

        // todo: could only deal with method=get
        if (!request.url.startsWith(webApiBaseUrl)) {
            return next.handle(request);
        }

        let urlPart = origUrl.replace(webApiBaseUrl, '');
        let newUrl = origUrl;

        if (urlPart.startsWith('quotes/configuration/getforquote')) {
            newUrl = '/mockup-data/quotes/configuration-list-items.json';
        } else if (urlPart.startsWith('config/lookup/getcustomers')) {
            newUrl = '/mockup-data/lookup/customers.json';
        } else if (urlPart.startsWith('config/account/accountprefs')) {
            newUrl = '/mockup-data/lookup/account-prefs.json';
        } else if (urlPart.startsWith('config/lookup/getaccountsbyjobrole')) {
            newUrl = '/mockup-data/lookup/accounts-by-job-role.json';
        } else if (urlPart.startsWith('quotes/quotes/getsimpleheader')) {
            newUrl = '/mockup-data/quotes/quote-simple-header.json';
        } else if (urlPart.startsWith('quotes/quotes/')) {
            newUrl = '/mockup-data/quotes/quote-edit.json';
        } else if (urlPart.startsWith('quotes/search')) {
            newUrl = '/mockup-data/quotes/quote-list-items.json';
        }        else if (urlPart.startsWith('auth/login/')) {
            console.debug('mock auth token requested');
            request = new HttpRequest("GET", "mockup-data/auth/token.txt", {responseType: "text"});
            return next.handle(request);
        }

        if (origUrl != newUrl) {
            request = request.clone({ url: newUrl });
            console.log(`mock data: ${origUrl} replaced by ${newUrl}`);
        }
        return next.handle(request);
      }
  }