import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Pages: START
	//import { AdministrationRouteWrapper } from './pages/administration/administration.component';
	import { AuthStatusRouteWrapper } from './pages/auth-status/auth-status.component';
	import { LiveStyleGuideRouteWrapper } from './pages/live-style-guide/live-style-guide.component';

		import { ScaffoldingExamplesRouteWrapper } from './pages/live-style-guide/scaffolding-examples/scaffolding-examples.component';
			import { NonResponsiveSquishyRouteWrapper } from './pages/live-style-guide/scaffolding-examples/non-responsive-squishy/non-responsive-squishy.component';
			import { NonResponsiveFirmRouteWrapper } from './pages/live-style-guide/scaffolding-examples/non-responsive-firm/non-responsive-firm.component';

	// import { QuotesRouteWrapper } from './pages/quotes/quotes.component';
	// 	import { QuoteOverviewRouteWrapper } from './pages/quotes/quote-overview/quote-overview.component';
	// 	import { QuoteConfigurationsRouteWrapper } from './pages/quotes/quote-configurations/quote-configurations.component';
	// 		import { CostToPriceRouteWrapper } from './pages/quotes/quote-configurations/cost-to-price/cost-to-price.component';
	// 			import { CostEntryComponent } from './pages/quotes/quote-configurations/cost-to-price/cost-entry/cost-entry.component';
	// 			import { PriceEntryComponent } from './pages/quotes/quote-configurations/cost-to-price/price-entry/price-entry.component';
	// 			import { FeaturesEntryComponent } from './pages/quotes/quote-configurations/cost-to-price/features-entry/features-entry.component';
	// 			import { CostToPriceComparisonsComponent } from './pages/quotes/quote-configurations/cost-to-price/cost-to-price-comparisons/cost-to-price-comparisons.component';
	// 			import { CostToPriceAssociationComponent } from './pages/quotes/quote-configurations/cost-to-price/cost-to-price-association/cost-to-price-association.component';
	// 			import { CostToPriceLinkComponent } from './pages/quotes/quote-configurations/cost-to-price/cost-to-price-link/cost-to-price-link.component';

	//import { NewQuoteRouteWrapper } from './pages/quotes/new-quote/new-quote.component';
	//import { ReportsRouteWrapper } from './pages/reports/reports.component';
	//import { SmartboardRouteWrapper } from './pages/smartboard/smartboard.component';


import { HomeComponent } from './home/home.component';
import { LoginComponent } from './pages/auth/login.component';
//	import { IqwRouteWrapper } from './pages/iqw/iqw.component';


import { AdministrationComponent } from 'src/app/pages/administration/administration.component';

// Pages: END

// todo: the # in the url when redirecting back from ping is causing angular to not find a match. thus, it falls back to the **, not found.
// until can figure out how to handle this, temp fix is to navigate to login from the auth service after token load. it still winds up showing "not found" but at least redirects.
const routes: Routes = [
	{ path: '',  component: HomeComponent }, //HomeComponent
	{ path: 'login', component: LoginComponent },
	{ path: 'public', loadChildren: () => import('./pages/public/public.module').then(m => m.PublicModule)},


	{ path: 'style-guide', loadChildren: () => import('./pages/style-guide/style-guide.module').then(m => m.StyleGuideModule)},




	{ path: 'hidden/status', component: AuthStatusRouteWrapper },
	{ path: 'live-style-guide', component: LiveStyleGuideRouteWrapper },
		{ path: 'live-style-guide/scaffolding-examples', component: ScaffoldingExamplesRouteWrapper },
			{ path: 'live-style-guide/scaffolding-examples/non-responsive-squishy', component: NonResponsiveSquishyRouteWrapper },
			{ path: 'live-style-guide/scaffolding-examples/non-responsive-firm', component: NonResponsiveFirmRouteWrapper },


	{ path: 'admin', loadChildren: () => import('./pages/administration/administration.module').then(m => m.AdministrationModule) },
	{ path: 'quotes', loadChildren: () => import('./pages/quotes/quotes.module').then(m => m.QuotesModule) },
	{ path: 'dashboards', loadChildren: () => import('./pages/dashboards/dashboards.module').then(m => m.DashboardsModule) },

	/*
	{ path: 'quotes', component: QuotesRouteWrapper },
		{ path: 'quotes/quote-overview', component: QuoteOverviewRouteWrapper },
		{ path: 'quotes/:quoteId/quote-overview', component: QuoteOverviewRouteWrapper },
		{ path: 'quotes/:quoteId/quote-configurations', component: QuoteConfigurationsRouteWrapper },
		{ path: 'quotes/:quoteId/quote-configurations/cost-to-price', component: CostToPriceRouteWrapper,
			children: [
				{ path: '', component: CostToPriceLinkComponent },
				//{ path: 'association', component: CostToPriceAssociationComponent },
				{ path: 'link', component: CostToPriceLinkComponent },
				{ path: 'cost', component: CostEntryComponent },
				{ path: 'price', component: PriceEntryComponent },
				{ path: 'features', component: FeaturesEntryComponent }
			]
		},
		*/

		/*
		{
			path: 'financial-summary',
			loadChildren: () => import('./financial-summary/financial-summary.module').then(mod => mod.FinancialSummaryModule)
		},
		*/

	//{ path: 'new-quote', component: NewQuoteRouteWrapper },

	{ path: 'reports', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule) },

	{ path: 'smartboard', loadChildren: () => import('./pages/smartboard/smartboard.module').then(m => m.SmartboardModule) },

	//{ path: 'smartboard', component: SmartboardRouteWrapper },
	//{ path: 'workbook', component: WorkbookRouteWrapper },

	{ path: 'iqw', loadChildren: () => import('./pages/iqw/iqw.module').then(m => m.IqwModule) },
	{ path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },
	{
		path: '**',
		redirectTo: 'public/not-found',
		
	  },


];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [
		RouterModule
	]
})

export class AppRoutingModule {}

export const routingComponents = [
	//AdministrationComponent,
	AuthStatusRouteWrapper,
	LiveStyleGuideRouteWrapper,
	ScaffoldingExamplesRouteWrapper,
	NonResponsiveSquishyRouteWrapper,
	NonResponsiveFirmRouteWrapper,
];
