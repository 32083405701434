import { HttpClient, HttpHeaders, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import {environment} from '../environments/environment';
//import 'rxjs/Rx';
import { AuthUser } from '../models/auth/auth-user.model';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { pingIdentityAuthConfig } from './auth.ping-identity.config';
import { OAuthService, AuthConfig, NullValidationHandler, JwksValidationHandler, LoginOptions } from 'angular-oauth2-oidc';
import { filter, delay } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs/Rx';
import { AuthApiService } from '../api-services/auth-api.service';
import { AccountPrefService } from './account-pref.service';
import { AccountApiService } from '../api-services/account-api.service';

// inspiration: https://stackblitz.com/edit/angular-8-jwt-authentication-example?file=src%2Fapp%2Flogin%2Flogin.component.ts
// https://github.com/manfredsteyer/angular-oauth2-oidc/issues/209
// https://angular.io/guide/router#canactivate-requiring-authentication

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  // subjects we base the observables off of
  private isAuthenticatedSubject: BehaviorSubject<boolean>;
  private isLoginResolvedSubject: BehaviorSubject<boolean>;
  private isLoggedInSubject: BehaviorSubject<boolean>;
  private authenticatedUserSubject: BehaviorSubject<AuthUser>;
  private isImpersonatingSubject: BehaviorSubject<boolean>;

  /** Indicates whether the user has been authenticated against the Identity provider. This does NOT mean he is authorized (yet). */
  public isAuthenticated$: Observable<boolean>;
  /** Indicates whether the app login attempt has been resolved (ie, yes you are a valid user; no you're not) */
  public isLoginResolved$: Observable<boolean>;
  /** Indicates the user has been authenticated against the Identity provider AND authenticated against the app. */
  public isLoggedIn$: Observable<boolean>;
  /** The user object */
  public authenticatedUser$: Observable<AuthUser>;
  /** Whether the user is impersonating another (only available to admins). */
  public isImpersonating$: Observable<boolean>;

  public redirectUrl : string = '';

  constructor(
    private router: Router,
    private oauthService: OAuthService, 
    private authApiService: AuthApiService, 
    private accountApiService : AccountApiService,
    private acctPrefService: AccountPrefService) {

    this.isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
    this.isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

    this.isLoginResolvedSubject = new BehaviorSubject<boolean>(false);
    this.isLoginResolved$ = this.isLoginResolvedSubject.asObservable();

    this.isLoggedInSubject = new BehaviorSubject<boolean>(false);
    this.isLoggedIn$ = this.isLoggedInSubject.asObservable();

    this.isImpersonatingSubject = new BehaviorSubject<boolean>(false);
    this.isImpersonating$ = this.isImpersonatingSubject.asObservable();

    // todo, should we immediately grab the json?
    this.authenticatedUserSubject = new BehaviorSubject<AuthUser>(JSON.parse(localStorage.getItem('authenticatedUser')));
    this.authenticatedUser$ = this.authenticatedUserSubject.asObservable();

    this.tryLogin();
  }

  tryLogin() {

    if (!environment.devSettings["useMockDataApi"] || false) {

      // configure oauth
      this.configureOAuthWithIdpSettings();
      
      // todo: handle other events, like single sign out, token expired, session_terminated, etc.
      // https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/events.html
      this.oauthService.loadDiscoveryDocumentAndTryLogin({
        onTokenReceived : (info) => {
          this.isAuthenticatedSubject.next(true);
          // todo: is this fired on silent refresh? if so, don't redirect
          console.debug('onTokenReceived');
          console.debug(info);
          this.redirectUrl = info.state;
          this.router.navigate(['login']).then(n => console.debug(`nav to login: ${n}`));
        }
      }).then(_ => {
        //if the user has not been authenticated against Ping, then initialize the auth flow
        if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
          // this will redirect to Ping
          // and come back to index.html
          console.debug('initImplicitFlow');
          // redirectUrl is set by the component (e.g. auth guard). don't get this from route because it gets the CURRENT route, not where it's coming from.
          this.oauthService.initImplicitFlow(encodeURIComponent(this.redirectUrl));
          return;
        }
        console.debug('tryLogin > no implicit flow');
        this.checkLogin();
      });
    }
    else {
      this.checkLogin();
    }
  }

  public isLoggedIn() {
    return this.isLoggedInSubject && this.isLoggedInSubject.getValue();
  }

  public isLoggedinResolved() {
    return this.isLoginResolvedSubject && this.isLoginResolvedSubject.getValue();
  }

  private checkLogin() {

    if (!this.isLoggedInSubject.value) {
      // go hit our api to get the user jwt
      this.authenticate();
    }

  }

  public authenticate() {

    var accessToken = "";
    var devModeSecret = "";

    if (environment.devSettings["devModeSecret"] && environment.devSettings["devModeUserId"]) {
      accessToken = environment.devSettings["devModeUserId"];
      devModeSecret = environment.devSettings["devModeSecret"];
      console.debug(`Environment config has devModeUserId set...Entering Development Mode! as UserId ${environment.devSettings["devModeUserId"]}`)
    }
    else {
      accessToken = localStorage.getItem("access_token");
    }

    console.debug(`AccessToken to be used for Ping: ${accessToken}`);

    return this.authApiService.login(accessToken, devModeSecret).subscribe(token => {
      localStorage.setItem("token", token);
      localStorage.removeItem("imp_token");
      let usrJson = window.atob(token.split('.')[1]);
      localStorage.setItem("authenticatedUser", usrJson);
      let usrObj = JSON.parse(usrJson) as AuthUser;
      this.authenticatedUserSubject.next(usrObj);
      this.isLoginResolvedSubject.next(true);
      this.isLoggedInSubject.next(true);
      this.isImpersonatingSubject.next(false);
      this.setAccountPrefs(usrObj.id);
    }, error => {
      //todo: redirect to forbidden if error
      localStorage.removeItem("token");
      localStorage.removeItem("imp_token");
      localStorage.removeItem("authenticatedUser");
      this.authenticatedUserSubject.next(null);
      this.isLoginResolvedSubject.next(true);
      this.isLoggedInSubject.next(false);
      this.isImpersonatingSubject.next(false);
    }); 

    // return this._http.get('https://localhost:44365/api/auth/login/accountlogin',
    //   {
    //     headers: new HttpHeaders(
    //       {
    //         "accessToken": accessToken,
    //         "devModeSecret": devModeSecret,
    //         "Content-Type": "text"
    //       }),
    //     observe: 'response',
    //     responseType: 'text'
    //   }).subscribe(data => {
    //     console.log("JWT Response: " + data.body),
    //       localStorage.setItem("token", data.body),
    //       this.authenticatedUser = JSON.parse(window.atob(data.body.split('.')[1])),
    //       this.LogUserModel();
    //   });
  }

  public authenticateImpersonatedUser(accountId : number) {
    var accessToken = accountId.toString(); //todo: this will be populated from a DD list. 

    console.log("Impersonation userId:" + accessToken);

    return this.authApiService.impersonate(accessToken).subscribe(token => {
      localStorage.setItem("imp_token", token);
      let usrJson = window.atob(token.split('.')[1]);
      localStorage.setItem("authenticatedUser", usrJson);
      let usrObj = JSON.parse(usrJson) as AuthUser;
      this.authenticatedUserSubject.next(usrObj);
      this.isLoginResolvedSubject.next(true);
      this.isLoggedInSubject.next(true);
      this.isImpersonatingSubject.next(true);

      this.setAccountPrefs(usrObj.id);
    }, error => {
      console.error(`Could not impersonate user ${accessToken}: ${error}`);
      localStorage.removeItem("imp_token");
      this.isImpersonatingSubject.next(false);
      return this.authenticate(); // try to re-authenticate
    });

    // return this._http.get('https://localhost:44365/api/auth/login/GetImpersonationToken',
    //   {
    //     headers: new HttpHeaders({ "accessToken": accessToken, "Content-Type": "text" }),
    //     observe: 'response',
    //     responseType: 'text'
    //   }).subscribe(data => {
    //     console.log("JWT IMP Response: " + data.body),
    //       localStorage.setItem("imp_token", data.body),
    //       this.authenticatedUser = JSON.parse(window.atob(data.body.split('.')[1])),
    //       this.LogUserModel();
    //   });
  }

  //todo: end impersonation

  cancelImpersonation() {
    return this.authenticate();
  }

  private configureOAuthWithIdpSettings() {
    console.debug('configuring oauth...');
    this.oauthService.configure(pingIdentityAuthConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    //console.debug(pingIdentityAuthConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    this.oauthService.events.subscribe(e => {
      // tslint:disable-next-line:no-console
      console.debug('oauth/oidc event', e);
    });

    // this.oauthService.events
    //   .pipe(filter(e => e.type === 'session_terminated'))
    //   .subscribe(e => {
    //     // tslint:disable-next-line:no-console
    //     console.log('Your session has been terminated!');
    //   });
  }

  private setAccountPrefs(id : number) {
    this.accountApiService.getAccountPrefsForAccount(id).subscribe(x => {
      this.acctPrefService.setAccountPrefs(x);
    })
  }

  public checkPermission(permissions: string[]) : boolean {

    const userSubj = this.authenticatedUserSubject.getValue();

    if (userSubj == null) {
      return false;
    }

    // if you're sysadmin you can do anything
    if (userSubj.roles.indexOf['sys-admin'] > 0) {
      console.debug('perm guard: user is sysadmin');
      return true;
    }

    // if there's no permissions or the user is not authenticated, then redirect to forbidden page
    if (permissions == null || permissions.length == 0 || !this.isLoggedIn$) {
      console.debug('perm guard: no roles requested, no permissions granted');
      //todo: redirect
      //this._router.navigate(['/forbidden']);
      return false;
    }

    // loop through the array of expected permissions, and see if the user has it
    // if so, immediately return
    for (let i = 0; i < permissions.length; i++) {
      let permName = permissions[i];
      if (userSubj.permissions[permName] != null && userSubj.permissions[permName].length > 0) {
        console.debug(`perm guard: ${permName} found. user has permissions.`);
        return true;
      }
    }

    // if get here, the user does not have the requested permissions
    console.debug(`user does not requested permissions ${permissions.join(',')}`);
    return false;
  }

  public checkPermissionForBusinessUnit(permissions: string[], businessUnitId: number) : boolean {

    const userSubj = this.authenticatedUserSubject.getValue();

    if (userSubj == null) {
      return false;
    }

    // if you're sysadmin you can do anything
    if (userSubj.roles.indexOf['sys-admin'] > 0) {
      console.debug('bu perm: user is sysadmin');
      return true;
    }

    // if there's no permissions or the user is not authenticated, then redirect to forbidden page
    if (permissions == null || permissions.length == 0 || !this.isLoggedIn$) {
      console.debug('bu perm: no roles requested, no permissions granted');
      //todo: redirect
      //this._router.navigate(['/forbidden']);
      return false;
    }

    // loop through the array of expected permissions, and see if the user has it
    // if so, immediately return
    for (let i = 0; i < permissions.length; i++) {
      let permName = permissions[i];
      if (userSubj.permissions[permName] != null && userSubj.permissions[permName].indexOf(businessUnitId) > -1) {
        console.debug(`bu perm: ${permName} found for bu ${businessUnitId}.`);
        return true;
      }
    }

    // if get here, the user does not have the requested permissions
    console.debug(`user does not requested permissions ${permissions.join(',')}`);
    return false;
  }
}
