import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-scaffolding-examples',
	templateUrl: './scaffolding-examples.component.html',
	styleUrls: ['./scaffolding-examples.component.scss']
})
export class ScaffoldingExamplesComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}


@Component({
        selector: 'app-scaffolding-examples-route-wrapper',
        templateUrl: './scaffolding-examples-route-wrapper.html'
})
export class ScaffoldingExamplesRouteWrapper implements OnInit {

	constructor() {
	}

        ngOnInit() {}
}

