import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { BaseApiService } from './base-api.service';
import { environment } from 'src/environments/environment';
import { AccountPrefs } from '../models/lookup/user-prefs.model';
import { AccountProfile } from '../models/lookup/account-profile.model';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService extends BaseApiService {
  private domainUrl = environment.webApiBaseUrl + 'config/account';  // URL to web api

  constructor(protected http: HttpClient, protected snackBar: MatSnackBar) { //private messageService: MessageService
    super(http, snackBar);
  }

  getAccountProfile(id: number): Observable<AccountProfile> {
    const url = `${this.domainUrl}/getprofile`; //${id}
    return this.http.get<AccountProfile>(url).pipe(
      tap(_ => this.log(`fetched acct prefs id=${id}`)),
      catchError(this.handleError<AccountProfile>(`getAccountPrefsForAccount id=${id}`))
    );
  }

  getAccountPrefsForAccount(id: number): Observable<AccountPrefs> {
    const url = `${this.domainUrl}/getprefs`; //${id}
    return this.http.get<AccountPrefs>(url).pipe(
      tap(_ => this.log(`fetched acct prefs id=${id}`)),
      catchError(this.handleError<AccountPrefs>(`getAccountPrefsForAccount id=${id}`))
    );
  }

  setAccountPrefsForAccount(id: number, prefs: AccountPrefs) {
    const url = `${this.domainUrl}/setprefs`; //${id}
    return this.http.put(url, prefs).pipe(
        tap(_ => {this.log(`updated prefs`); this.snackSuccess('Preferences updated!')}),
        catchError(this.handleError<any>('setAccountPrefsForAccount'))
      );
  }
}
