import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const re = "/login";
        var token = "";
        
        if(localStorage.getItem('token') != null) {   
            token = localStorage.getItem('token') 
            if(localStorage.getItem('imp_token') != null) {
                token = localStorage.getItem('imp_token') 
            }
        }
        else token = "";        

        // Exclude interceptor for login request:
        if (request.url.search(re) === -1 ) {
            request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        }
        return next.handle(request);
    }
}