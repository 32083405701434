import { BrowserModule } from '@angular/platform-browser';
import {
	AuthConfig,
	JwksValidationHandler,
	OAuthModule,
	ValidationHandler
} from 'angular-oauth2-oidc';
import { NgModule } from '@angular/core';

import { AgGridModule } from 'ag-grid-angular';

import { Grid } from 'ag-grid-community';
import 'ag-grid-enterprise';


import { DragDropModule } from '@angular/cdk/drag-drop';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


//import { ScrollingModule } from '@angular/cdk/scrolling';

// Router
import { AppRoutingModule, routingComponents } from './app-routing.module';
// AppComponent ( the root component )
import { AppComponent } from './app.component';
// Entire library of Angular Material components
import { MaterialComponentsModule } from './material-modules/material-components.module';
// Example Material components
import { ComponentExamplesModule } from './component-examples/component-examples.module';

import { BASE_URL } from './app.tokens';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
// App Common Components: START


// Main nav
import { MainNavComponent } from './app-common-components/main-nav/main-nav.component';

// App Common Components: END

// Pages: START
import { LiveStyleGuideComponent } from './pages/live-style-guide/live-style-guide.component';

import { AuthStatusComponent } from './pages/auth-status/auth-status.component';
//import { SmartboardComponent } from './pages/smartboard/smartboard.component';

// Pages: END


import { ScaffoldingExamplesComponent } from './pages/live-style-guide/scaffolding-examples/scaffolding-examples.component';
import { NonResponsiveSquishyComponent } from './pages/live-style-guide/scaffolding-examples/non-responsive-squishy/non-responsive-squishy.component';
import { NonResponsiveFirmComponent } from './pages/live-style-guide/scaffolding-examples/non-responsive-firm/non-responsive-firm.component';


//import { RouterModule } from '@angular/router';



import { CostToPriceService } from 'src/app/get-cost-price.service';


import { MockDataInterceptor } from './core/interceptors/mock-data.interceptor';
import { HomeComponent } from './home/home.component';
import { AuthModule } from './pages/auth/auth.module';
import { CodeEditor } from 'src/app/app-common-components/ag-grid-cell-editors/ag-code-column-component';
import { IsActiveEditor } from 'src/app/app-common-components/ag-grid-cell-editors/ag-is-active-column-component';
import { DecimalEditor } from './app-common-components/ag-grid-cell-editors/ag-decimal-column-component';
import { KeyValueDropDownEditor } from './app-common-components/ag-grid-cell-editors/key-value-drop-down.editor';

/*
import { HowToSurfModule } from 'src/app/how-to-surf/how-to-surf.module';
import { HowToSurfRoutingModule } from 'src/app/how-to-surf/how-to-surf-routing.module';
import { HowToSurfWidgetsModule } from './how-to-surf/how-to-surf-widgets/how-to-surf-widgets.module';
*/

/*
import { AdministrationModule } from 'src/app/pages/administration/administration.module';
import { AdministrationRoutingModule } from 'src/app/pages/administration/administration-routing.module';
import { AdminWidgetsModule } from 'src/app/pages/administration/admin-widgets/admin-widgets.module';

*/


@NgModule({
	declarations: [
		AppComponent,
		routingComponents,
		LiveStyleGuideComponent,
		AuthStatusComponent,
		
		MainNavComponent,
		ScaffoldingExamplesComponent,
		NonResponsiveSquishyComponent,
		NonResponsiveFirmComponent,
		HomeComponent,
		CodeEditor,
		IsActiveEditor,
		KeyValueDropDownEditor,
		DecimalEditor
	],
	imports: [
		//ScrollingModule,
		DragDropModule,
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MaterialComponentsModule,
		//AdministrationModule,
		//AdministrationRoutingModule,
		//AdminWidgetsModule,
		ComponentExamplesModule,
		FormsModule,
		ReactiveFormsModule,
		AgGridModule.withComponents([CodeEditor,IsActiveEditor,DecimalEditor,KeyValueDropDownEditor]),
		AuthModule,
		OAuthModule.forRoot({
			resourceServer: {
				allowedUrls: ['http://www.angular.at/api'],
				sendAccessToken: true
			}
		})
	],
	exports: [
		MaterialComponentsModule
	],
	providers: [
		//WindowRef,
		CostToPriceService,
		//{ provide: WindowRef },
		{ provide: CostToPriceService },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MockDataInterceptor,
			multi: true,
			// todo: use useFactory property to conditionally import
		},
		{
			provide: BASE_URL,
			useValue: 'http://www.angular.at'
		}
	],
	//providers: [WindowRef, CostToPriceService],
	bootstrap: [AppComponent]
})

export class AppModule { }

