import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-non-responsive-firm',
	templateUrl: './non-responsive-firm.component.html',
	styleUrls: ['./non-responsive-firm.component.scss']
})
export class NonResponsiveFirmComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}


@Component({
        selector: 'app-non-responsive-firm-route-wrapper',
        templateUrl: './non-responsive-firm-route-wrapper.html'
})
export class NonResponsiveFirmRouteWrapper implements OnInit {

	constructor() {
	}

        ngOnInit() {}
}
