import { Component, OnInit } from '@angular/core';

/**
 * @title Basic use of the tab group
 */
@Component({
	selector: 'tab-group-basic-example',
	templateUrl: 'tab-group-basic-example.html',
	styleUrls: ['tab-group-basic-example.css'],
})
export class TabGroupBasicExample implements OnInit {

	constructor() { }


	ngOnInit() {
	}


	navLinks = [
		{ path: 'dashboard', label: 'Dashboard' },
		{ path: 'smartboard', label: 'Smartboard' },
		{ path: 'quotes', label: 'Quotes' },
		{ path: 'iqw', label: 'IQW' },
		{ path: 'reports', label: 'Reports' },
		{ path: 'admin', label: 'Admin' },
		// moved to above nav bar { path: 'profile', label: 'Profile' },
		{ path: 'live-style-guide', label: 'Live Style Guide' },
	];


}
