import { Injectable } from '@angular/core';

import { AssemblyCostInterface, AssemblyCostToPriceInterface, IdNameInterface } from './assembly-cost.interface';
import { AssemblyPriceInterface } from './assembly-price.interface';
import { QUOTES } from './mock-quotes';

import {
	//FEATURES_OF_SPECIFIC_SEAT_TYPE_LIST,
	ASSEMBLY_COST,
	ASSEMBLY_PRICE,
	ASSEMBLY_COST_TO_PRICE
} from 'src/app/mock-cost-price'
// We'll simulate getting data from the server with the RxJS of() function.
import { Observable, of } from 'rxjs';
import { ALL_FEATURES_OF_ALL_SEAT_TYPES } from './mock-seat-features';


@Injectable({
	providedIn: 'root'
})

export class CostToPriceService {

		constructor() { }

		//getAllSeatTypeFeatures(): Observable<string[]> {
		//	return of(ALL_FEATURES_OF_ALL_SEAT_TYPES);
		//}

		//getSpecificSeatTypeFeatures(): Observable<SeatTypeInterface[]> {
		//	return of(FEATURES_OF_SPECIFIC_SEAT_TYPE_LIST);
		//}
		getCostToPriceData(): Observable<AssemblyCostToPriceInterface[]> {
				let costToPice = ASSEMBLY_COST_TO_PRICE;
				return of(costToPice);
		}
		getPrice(id: number): AssemblyPriceInterface {
		    let priceList = ASSEMBLY_PRICE;
		    let price = priceList.find(price => price.id === id);
		    return price;
		    //return of();
		}
		getCost(id: number):AssemblyCostInterface {
		    let costList = ASSEMBLY_COST;
		    let cost = costList.find(cost => cost.id === id);
			return cost;		
				
		}


}
