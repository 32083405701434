import { Component, OnInit } from '@angular/core';
//import { authConfig } from '../../auth.config';
//import {pingIdentityAuthConfig} from '../../auth.ping-identity.config';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthenticationService } from '../../core/services/authentication.service';


@Component({
	selector: 'app-auth-status',
	templateUrl: './auth-status.component.html',
	styleUrls: ['./auth-status.component.scss']
})

export class AuthStatusComponent implements OnInit {
	loginFailed: boolean = false;
	userProfile: object;

	constructor(private oauthService: OAuthService, private userService: AuthenticationService) {}

	ngOnInit() {}

	login() {}
	
	logout() {
		this.oauthService.logOut();
	}

	GetAll() {
		this.userService.authenticate();
	}

	GetSecured() {
		//this._dataService.GetSecured();
	}

	ClearTokens(){
		if(localStorage.getItem("token") != null) localStorage.removeItem('token');
		if(localStorage.getItem("imp_token") != null) localStorage.removeItem('imp_token');
	}

	GetSecuredApi() {
		//this._dataService.GetSecuredApi();
	}

	GetNonSecuredApi() {
		//this._dataService.GetNonSecuredApi();
	}
	
	GetImpersonationToken() {
		this.userService.authenticateImpersonatedUser(2);
	}

	loadUserProfile(): void {
		this.oauthService.loadUserProfile().then(up => (this.userProfile = up));
	}

	get givenName() {
		var claims = this.oauthService.getIdentityClaims();
		if (!claims) return null;
		return claims['given_name'];
	}

	get familyName() {
		var claims = this.oauthService.getIdentityClaims();
		if (!claims) return null;
		return claims['family_name'];
	}

	testSilentRefresh() {
		this.oauthService.oidc = true;

		this.oauthService
			.silentRefresh()
			.then(info => console.debug('refresh ok', info))
			.catch(err => console.error('refresh error', err));
		}

		set requestAccessToken(value: boolean) {
		this.oauthService.requestAccessToken = value;
		localStorage.setItem('requestAccessToken', '' + value);
	}

	get requestAccessToken() {
		return this.oauthService.requestAccessToken;
	}

	get id_token() {
		return this.oauthService.getIdToken();
	}

	get access_token() {
		return this.oauthService.getAccessToken();
	}

	get id_token_expiration() {
		return this.oauthService.getIdTokenExpiration();
	}

	get access_token_expiration() {
		return this.oauthService.getAccessTokenExpiration();
	}
}


@Component({
        selector: 'app-auth-status-route-wrapper',
        templateUrl: './auth-status-route-wrapper.html'
})

export class AuthStatusRouteWrapper implements OnInit {

constructor() {	}
    ngOnInit() {}
}
