import { Component } from '@angular/core';

@Component({
	selector: 'button-toggle-overview-example',
	templateUrl: './button-toggle-overview-example.html',
	styleUrls: ['./button-toggle-overview-example.scss']
})

export class ButtonToggleOverviewExample {

	constructor() {}
}
