import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

//import { MessageService } from './message.service';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material';


@Injectable({ providedIn: 'root' })
export class BaseApiService {

  protected API_URL_BASE: string = environment.webApiBaseUrl;

  protected httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(protected http: HttpClient, protected snackBar: MatSnackBar) { } //private messageService: MessageService

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<T> (operation = 'operation', result?: T, showSnack : boolean = true) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // todo: maybe need a param on handleError<>() to optionally display the snackbar error
	/*
      if (showSnack) {
        this.snackError(`An error occurred: ${operation}`);
      }
      */

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  protected log(message: string) {
    console.debug(message);
    //this.messageService.add(`HeroService: ${message}`);
  }

  protected snackSuccess(message: string) {
    this.snackBar.open(message, '', { panelClass: 'sft-snackbar-success', duration: 1500, horizontalPosition: 'right', verticalPosition: 'bottom' });
  }

  protected snackError(message: string) {
    // todo: link to error page? or this may need to be a component with a routerlink
    this.snackBar.open(message, '', { panelClass: 'sft-snackbar-error', duration: 3000, horizontalPosition: 'right', verticalPosition: 'bottom' });
  }
}