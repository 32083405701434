import {AfterViewInit, Component, ViewChild, ViewContainerRef} from "@angular/core";

import {ICellEditorAngularComp} from "ag-grid-angular";

@Component({
  selector: 'app-key-value-drop-down-editor',
  template: `
    <select  #input  [(ngModel)]="value">
      <option *ngFor="let item of options" value="{{item.Id}}">{{item.Dsc}}</option>
    </select>
  `
})
export class KeyValueDropDownEditor implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: number;
  public options: any;

  @ViewChild('input', { read: ViewContainerRef, static: true }) public input;


  agInit(params: any): void {
      this.params = params;
      this.value = this.params.value;
      this.options = params.options;
  }

  getValue(): any {
      return this.value;
  }

  ngAfterViewInit() {
      window.setTimeout(() => {
          this.input.element.nativeElement.focus();
      })
  }

}