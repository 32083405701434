import {AfterViewInit, Component, ViewChild, ViewContainerRef} from "@angular/core";

import {ICellEditorAngularComp} from "ag-grid-angular";

@Component({
    selector: 'decimal-cell',
    template: `<input #input (keydown)="onKeyDown($event)" (change)="onChange($event)" [(ngModel)]="value" style="width: 100%">`
})
export class DecimalEditor implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value: number;
    private cancelBeforeStart: boolean = false;
    regex=/^\d+\.?\d*$/;
    charRegex=/[\d.]/;
    @ViewChild('input', {read: ViewContainerRef,static:false}) public input;


    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
        if(this.params.type!=null && this.params.type=='number'){
            this.regex=/^\d*$/;
            this.charRegex=/[\d]/;
        }


        // only start edit if key pressed is a number, not a letter
        //this.cancelBeforeStart = params.charPress && ('1234567890.'.indexOf(params.charPress) < 0);
    }

    getValue(): any {
        return this.value;
    }

    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd(): boolean {
         let result= this.regex.test(this.value.toString());

        if(this.params.isValid)
        {
            result= this.params.isValid(this.params.data,this.value);
           
        }
        return !result;
    };

    onKeyDown(event): void {
        if (!this.isKeyPressedNumeric(event)) {
            if (event.preventDefault) event.preventDefault();
        }
    }
    onChange(event):void{
        let result= this.regex.test(this.value.toString());
        if(result){
            if(this.params.isValid!=null)
            result= this.params.isValid(this.params.data,this.value);
            if(!result)
                this.input.element.nativeElement.style.color='red';
            // this.params.api.setFocusedCell(this.params.rowIndex, this.params.column.colId);
            //     this.params.api.startEditingCell({
            //     rowIndex:  this.params.rowIndex,
            //     colKey: this.params.column.colId
            //     });
        }
        else{
            this.input.element.nativeElement.style.color='black';
        }
    }
    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
         window.setTimeout(() => {
             this.input.element.nativeElement.focus();
         })
    }

    focusIn(){
        window.setTimeout(() => {
            this.input.element.nativeElement.focus();
        })
    }
    private getCharCodeFromEvent(event): any {
        event = event || window.event;
        return (typeof event.which == "undefined") ? event.keyCode : event.which;
    }

    private isCharNumeric(charStr): boolean {

        return this.charRegex.test(charStr);
    }

    private isKeyPressedNumeric(event): boolean {
        const charCode = this.getCharCodeFromEvent(event);
        const charStr = event.key ? event.key : String.fromCharCode(charCode);
        if(charCode===8 || charCode===127 || charCode===9|| charCode===16)
            return true;
        return this.isCharNumeric(charStr);
    }
}