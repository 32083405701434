// This api will come in the next version

import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const pingIdentityAuthConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.idpConfig.issuer, //'https://logindev.rockwellcollins.com:443',

  //loginUrl: 'https://logindev.rockwellcollins.com:443/as/token.oauth2',

  // URL of the SPA to redirect the user to after login
  //redirectUri:  'http://localhost:4200/authcallback',
  redirectUri: window.location.origin + '/index.html',

  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: environment.idpConfig.clientId, // 'IS_Quote_dev',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid', //openid profile email

  clearHashAfterLogin: true,

  // silentRefreshShowIFrame: true,

  showDebugInformation: true,

  sessionChecksEnabled: false,
  //requireHttps: false
  
};
