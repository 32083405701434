import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AuthUser } from 'src/app/core/models/auth/auth-user.model';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-main-nav',
	templateUrl: './main-nav.component.html',
	styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {

	currentUser: AuthUser;
	isLoggedIn: boolean;
	isImpersonating: boolean;

	navLinks = [];
	showAdmin: boolean = false;

	APP_TITLE : string;
	APP_VERSION : string;

	constructor(private authService: AuthenticationService) {}

	ngOnInit() {
		this.APP_TITLE = environment.appTitle;
		this.APP_VERSION = environment.appVersion;
		
		this.authService.isLoggedIn$.subscribe(x => this.isLoggedIn = x);
		this.authService.isImpersonating$.subscribe(x => this.isImpersonating = x);
		this.authService.authenticatedUser$.subscribe(x => {
			this.currentUser = x;
			this.showAdmin = this.authService.checkPermission(['GlobalConfigEdit']);
			this.buildNav();
		});
	}

	buildNav() {

		this.navLinks = [];


		


		
		
		if (this.authService.checkPermission(['DashboardView'])) {
			
			//this.navLinks.push({ path: 'dashboards/sales', label: 'Dashboard' });
		}


		if (this.authService.checkPermission(['SmartboardView'])) {
			
			// Unsure if this is the actual correct path that we will be using, for the moment it's commented out,
			// And instead the path below this line is wired up. Update it as needed. 
			//this.navLinks.push({ path: 'dashboards/smartboard', label: 'Smartboard' });

			this.navLinks.push({ path: 'smartboard', label: 'Dashboard' });
		}

		this.navLinks.push({ path: 'quotes/6342/quote-configurations/cost-to-price/costs', label: 'Cost to Price' });


		if (this.authService.checkPermission(['QuoteView'])) {
			this.navLinks.push({ path: 'quotes', label: 'Quotes' });
		}
		if (true) { //todo this.authService.checkPermission([''])
			//this.navLinks.push({ path: 'iqw', label: 'IQW' });
		}
		if (true) { //todo this.authService.checkPermission([''])
			//this.navLinks.push({ path: 'reports', label: 'Reports' });
		}

		// only show live style guide in non-prod environment
		if (!environment.production) {
			this.navLinks.push({ path: 'live-style-guide', label: 'Live Style Guide' });
		}


		if (!environment.production) {
			//this.navLinks.push({ path: 'style-guide', label: 'Style Guide' });
		}
	}

	stopImpersonating() {
		this.authService.cancelImpersonation();
	}

}
