import { pingIdentityAuthConfig } from './core/services/auth.ping-identity.config';
import { OAuthService, AuthConfig, NullValidationHandler, JwksValidationHandler } from 'angular-oauth2-oidc';
import { CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthenticationService } from './core/services/authentication.service'
import { AuthUser } from './core/models/auth/auth-user.model';


import {FormControl} from '@angular/forms';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}


const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {



	toppings = new FormControl();
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  APP_TITLE: string = environment.appTitle;
  APP_NON_PROD_NAME: string = environment.nonProdName;
  APP_VERSION: string = environment.appVersion;

  currentUser: AuthUser;
  isLoggedIn: boolean;

  title = 'swift-global-ui';
  columns: any[] = [
    { field: 'position' },
    { field: 'name' },
    { field: 'weight' },
    { field: 'symbol' }
  ];
  displayedColumns: string[] = [];
  dataSource = ELEMENT_DATA;
  previousIndex: number;

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    this.authService.isLoggedIn$.subscribe(x => this.isLoggedIn = x);
    this.authService.authenticatedUser$.subscribe(x => this.currentUser = x);
    // if (!this.isLoggedIn) {
    //   this.authService.tryLogin();
    // }
  }

  setDisplayedColumns() {
    this.columns.forEach((colunm, index) => {
      colunm.index = index;
      this.displayedColumns[index] = colunm.field;
    });
  }

  dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.columns, this.previousIndex, index);
      this.setDisplayedColumns();
    }
  }


}
